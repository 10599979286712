define('rangy',["rangy-core",
        "rangy-classapplier",
        "rangy-highlighter",
        "rangy-textrange", 
        "rangy-position"

        //"domReady" //"domReady!" forces wait for DOM doc

        /*
        "rangy-serializer",
        "rangy-selectionsaverestore"
        */
        ],
function (core, textrange, cssclassapplier, highlighter, position
//, domReady
/* serializer, selectionsaverestore */
) {

// domReady(function(){
//     core.init();
// });


return core;

});
